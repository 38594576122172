interface Props {
  className?: string;
  strokeWidth?: number;
}

export default function MusicIcon({ className = "icon", strokeWidth = 1.5 }: Props) {
  return (
    <svg
      className={className}
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 13.5H19.5V21.75C19.5 22.7446 19.1049 23.6984 18.4017 24.4016C17.6984 25.1049 16.7446 25.5 15.75 25.5C14.7554 25.5 13.8016 25.1049 13.0983 24.4016C12.3951 23.6984 12 22.7446 12 21.75C12 20.7554 12.3951 19.8016 13.0983 19.0984C13.8016 18.3951 14.7554 18 15.75 18C16.605 18 17.37 18.285 18 18.75V10.5H24V13.5ZM18 3C19.9698 3 21.9204 3.38799 23.7403 4.14181C25.5601 4.89563 27.2137 6.00052 28.6066 7.3934C29.9995 8.78628 31.1044 10.4399 31.8582 12.2597C32.612 14.0796 33 16.0302 33 18C33 21.9782 31.4196 25.7936 28.6066 28.6066C25.7936 31.4196 21.9782 33 18 33C16.0302 33 14.0796 32.612 12.2597 31.8582C10.4399 31.1044 8.78628 29.9995 7.3934 28.6066C4.58035 25.7936 3 21.9782 3 18C3 14.0218 4.58035 10.2064 7.3934 7.3934C10.2064 4.58035 14.0218 3 18 3ZM18 6C14.8174 6 11.7652 7.26428 9.51472 9.51472C7.26428 11.7652 6 14.8174 6 18C6 21.1826 7.26428 24.2348 9.51472 26.4853C11.7652 28.7357 14.8174 30 18 30C21.1826 30 24.2348 28.7357 26.4853 26.4853C28.7357 24.2348 30 21.1826 30 18C30 14.8174 28.7357 11.7652 26.4853 9.51472C24.2348 7.26428 21.1826 6 18 6Z"
        fill="currentColor"
      />
    </svg>
  );
}
