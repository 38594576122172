interface Props {
  className?: string;
  strokeWidth?: number;
}

export default function PlusCircle({ strokeWidth = 1.5, className = "icon" }: Props) {
  return (
    <svg
      className={`!w-12 !h-12 !stroke-rocola-blue !fill-none ${className}`}
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
