interface Props {
  className?: string;
}

export default function SpotifyLogo({
  className = "w-8 h-8 sm:w-[2.75rem] sm:h-[2.75rem] fill-[#1db954] stroke-none",
}: Props) {
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 2362 709"
      xmlSpace="preserve"
    >
      <path
        fill="transparent"
        opacity="1.000000"
        stroke="none"
        d="
M1545.000000,710.000000 
	C1030.000000,710.000000 515.500000,710.000000 1.000000,710.000000 
	C1.000000,473.666656 1.000000,237.333328 1.000000,1.000000 
	C788.333313,1.000000 1575.666626,1.000000 2363.000000,1.000000 
	C2363.000000,237.333328 2363.000000,473.666656 2363.000000,710.000000 
	C2090.500000,710.000000 1818.000000,710.000000 1545.000000,710.000000 
M19.001205,383.243225 
	C20.331125,393.124512 21.040918,403.138702 23.124622,412.858398 
	C27.035501,431.101196 30.663601,449.521149 36.376205,467.233032 
	C44.321545,491.867523 55.245346,515.382507 69.093246,537.376526 
	C85.207268,562.969788 103.254005,587.054016 125.905914,607.317566 
	C137.169724,617.393799 148.425095,627.561157 160.427612,636.712341 
	C183.319290,654.165955 208.350357,668.156982 235.063416,678.868774 
	C258.010742,688.070496 281.484833,695.852173 306.150055,698.999817 
	C324.509399,701.342651 342.928833,703.316467 361.371735,704.850708 
	C368.392731,705.434814 375.566284,704.496826 382.648468,703.962769 
	C393.946411,703.110657 405.232758,702.085815 416.512085,701.009155 
	C437.356964,699.019348 457.477814,693.671509 477.298737,687.239685 
	C500.726807,679.637390 523.002502,669.426392 544.313538,657.006897 
	C568.343018,643.003235 590.237183,626.209534 610.301514,607.070618 
	C625.685242,592.396423 639.361084,576.200867 651.781311,558.871887 
	C670.315796,533.012268 685.095703,505.232269 695.946655,475.386475 
	C702.612061,457.053162 708.346802,438.398956 711.023438,418.990295 
	C713.190125,403.278839 716.009644,387.568237 716.764954,371.772400 
	C717.861267,348.846100 717.553955,325.798950 712.936340,303.151520 
	C709.337769,285.502197 705.842163,267.771820 700.944519,250.461197 
	C693.201477,223.093246 681.033508,197.580795 666.136353,173.305115 
	C652.709656,151.425705 637.283142,131.199463 619.365906,112.823029 
	C605.866577,98.977722 591.601074,86.056038 576.066406,74.495697 
	C553.727295,57.871635 529.746643,44.079334 504.198639,33.172665 
	C483.724274,24.431982 462.549622,17.369490 440.602325,13.453728 
	C422.601044,10.241999 404.412903,7.291478 386.193604,6.281829 
	C362.124237,4.947989 337.883881,5.287214 314.081879,10.085307 
	C296.746979,13.579736 279.386810,17.239603 262.396179,22.082550 
	C237.447723,29.193756 213.968658,40.025066 191.543259,53.103615 
	C169.576859,65.914490 149.154877,80.897514 130.576508,98.182137 
	C120.057556,107.968567 110.656891,118.973557 100.936790,129.598251 
	C85.537567,146.430603 73.008995,165.381760 62.077362,185.268402 
	C43.547550,218.977478 30.274740,254.628067 23.838631,292.766754 
	C20.243238,314.072113 18.176140,335.440430 18.061850,357.003143 
	C18.017174,365.431610 18.666754,373.863739 19.001205,383.243225 
M2211.001465,411.459869 
	C2218.342773,392.380676 2225.662842,373.293243 2233.030762,354.224335 
	C2242.330078,330.156342 2251.634766,306.090332 2261.015137,282.053802 
	C2263.198242,276.459869 2261.699951,274.010986 2255.653809,274.005554 
	C2238.156982,273.989868 2220.658203,274.132050 2203.164307,273.903381 
	C2199.002441,273.848969 2197.212402,275.766449 2196.019775,279.192108 
	C2189.032227,299.265289 2181.991943,319.320007 2175.016602,339.397400 
	C2164.313965,370.204010 2153.662354,401.028320 2142.943848,431.829315 
	C2142.192871,433.987427 2141.071289,436.016571 2139.869629,438.661499 
	C2138.376221,434.697235 2137.195068,431.520966 2135.984619,428.356110 
	C2127.115479,405.169922 2118.266357,381.976135 2109.360840,358.804047 
	C2099.290527,332.602112 2089.026611,306.473206 2079.206055,280.178375 
	C2077.555908,275.760040 2075.723633,273.871582 2070.875977,273.905426 
	C2043.049072,274.099762 2015.219849,274.000000 1987.391479,274.000000 
	C1985.644043,274.000000 1983.896729,274.000000 1982.000366,274.000000 
	C1982.000366,269.248993 1981.600830,265.206726 1982.081299,261.271942 
	C1983.164307,252.402512 1984.938110,243.569046 1993.780884,238.845810 
	C2002.380249,234.252640 2011.801514,235.632599 2020.894897,236.571198 
	C2026.469604,237.146606 2031.877930,239.299881 2037.371338,240.704575 
	C2042.617310,242.046036 2044.980713,240.301056 2044.990967,235.023224 
	C2045.017944,221.192368 2045.000366,207.361435 2044.999512,193.530533 
	C2044.999268,190.183029 2044.372437,187.415604 2040.316650,186.669632 
	C2030.564697,184.875916 2020.896851,181.719025 2011.100830,181.296387 
	C1998.469238,180.751373 1985.606323,181.396988 1973.098877,183.253082 
	C1958.864502,185.365494 1946.400635,192.243378 1937.195801,203.616058 
	C1924.405273,219.418961 1920.296509,238.189072 1920.010498,258.048737 
	C1919.939575,262.971130 1920.000000,267.895416 1920.000000,273.000305 
	C1911.631348,273.000305 1903.978027,273.020172 1896.325073,272.992615 
	C1891.948853,272.976837 1889.814575,274.580811 1889.906250,279.475189 
	C1890.171265,293.634399 1890.223877,307.807129 1889.884277,321.963165 
	C1889.757446,327.250366 1891.382324,329.080719 1897.264526,329.018921 
	C1904.710205,328.940643 1912.157349,329.000000 1920.000000,329.000000 
	C1920.000000,331.470093 1920.000000,333.431152 1920.000000,335.392212 
	C1920.000000,393.881927 1920.074707,452.371857 1919.884766,510.860962 
	C1919.865723,516.706421 1921.983521,518.044739 1927.159668,518.023376 
	C1942.823364,517.958679 1958.487305,518.000732 1974.151245,517.999756 
	C1982.186523,517.999207 1982.999390,517.207458 1982.999512,509.366486 
	C1983.000366,451.210052 1983.000000,393.053619 1983.000000,334.897186 
	C1983.000000,332.982544 1983.000000,331.067871 1983.000000,329.000000 
	C1998.079590,329.000000 2012.408813,329.222229 2026.723877,328.868805 
	C2031.201050,328.758301 2033.236694,330.515442 2034.715576,334.274567 
	C2038.399902,343.639893 2042.177124,352.970734 2046.064697,362.253540 
	C2055.600098,385.022247 2065.278809,407.730896 2074.786865,430.510895 
	C2084.667480,454.183990 2094.410645,477.914520 2104.225342,501.615234 
	C2106.824951,507.892914 2110.358643,513.577454 2105.227295,520.852783 
	C2098.652832,530.174622 2091.363525,536.852173 2079.136475,536.041077 
	C2069.513428,535.402649 2060.640381,532.495728 2052.201904,528.030762 
	C2048.350830,525.993164 2045.726318,527.093384 2044.005127,530.957458 
	C2038.250488,543.876953 2032.556519,556.824585 2026.653687,569.676086 
	C2025.271362,572.685791 2026.249634,574.683350 2028.680176,575.861816 
	C2034.675537,578.768555 2040.737183,581.590332 2046.948242,583.990601 
	C2062.116943,589.852478 2077.996338,590.471863 2094.026855,590.046631 
	C2103.820068,589.786743 2113.160400,587.640686 2121.912109,583.491333 
	C2139.688477,575.063477 2151.244629,560.190308 2158.743164,542.911743 
	C2171.657959,513.152771 2183.004150,482.712769 2194.991211,452.551636 
	C2200.326416,439.127228 2205.619629,425.686188 2211.001465,411.459869 
M1328.069824,463.611603 
	C1343.824951,434.278442 1346.697266,403.037994 1342.063477,370.607758 
	C1338.839722,348.045959 1330.815918,327.681946 1316.451660,309.747437 
	C1306.075439,296.792236 1293.487427,286.665344 1278.771973,279.508362 
	C1258.039795,269.424988 1235.949097,266.742340 1213.123901,269.979187 
	C1195.331299,272.502411 1179.833740,279.835327 1166.571655,291.929626 
	C1162.789429,295.378876 1159.041748,298.866028 1154.999878,302.592316 
	C1154.999878,295.037506 1155.050171,287.746307 1154.983154,280.456207 
	C1154.934082,275.111694 1152.845459,273.031219 1147.446289,273.016388 
	C1131.117920,272.971466 1114.789429,273.012604 1098.460938,272.991821 
	C1094.176025,272.986359 1091.911865,274.366577 1091.917358,279.336395 
	C1092.027466,380.472809 1092.021973,481.609406 1091.931885,582.745850 
	C1091.927612,587.567017 1093.736572,589.121643 1098.408569,589.070007 
	C1114.735107,588.889343 1131.068848,588.790527 1147.391235,589.106812 
	C1153.602417,589.227234 1155.031250,587.695557 1155.020630,581.310059 
	C1154.973389,552.652039 1155.000000,523.993896 1155.000000,495.335754 
	C1155.000000,493.750549 1155.000000,492.165344 1155.000000,489.181549 
	C1158.647949,492.958160 1161.064331,496.201233 1164.169678,498.522675 
	C1170.939941,503.584045 1177.596313,509.143280 1185.132935,512.763367 
	C1202.011963,520.870972 1219.917358,523.965515 1238.849854,522.185608 
	C1253.027710,520.852600 1266.453003,517.570740 1279.132812,511.350372 
	C1300.383667,500.925201 1316.462036,484.998962 1328.069824,463.611603 
M1036.447388,499.034668 
	C1038.230225,497.591644 1040.119629,496.261047 1041.779419,494.688171 
	C1060.323120,477.117096 1071.280640,456.104645 1071.008301,430.014984 
	C1070.950928,424.516815 1070.942505,419.016754 1071.012085,413.518860 
	C1071.150146,402.621094 1067.885620,392.529663 1062.919922,383.111755 
	C1054.806641,367.723969 1041.908813,357.136230 1026.812622,348.948212 
	C1006.176392,337.755432 983.829529,331.490753 961.210144,326.030090 
	C945.560059,322.251892 929.982056,318.187042 915.594543,310.804626 
	C899.797729,302.699066 893.961243,290.960663 900.078796,271.975342 
	C903.549011,261.205658 912.997070,256.386292 922.894470,253.044861 
	C939.192383,247.542557 955.701416,249.398026 971.880432,253.520599 
	C991.195923,258.442413 1008.199951,268.372223 1024.311401,279.856659 
	C1028.599487,282.913239 1030.774170,282.795654 1033.693481,278.698181 
	C1042.674316,266.093262 1051.647949,253.483246 1060.592896,240.852982 
	C1063.504761,236.741501 1063.301147,233.631012 1059.277832,230.888794 
	C1050.275146,224.752518 1041.396729,218.306931 1031.853027,213.117859 
	C1012.372253,202.525848 991.501770,196.289993 969.315796,193.763489 
	C952.482666,191.846558 935.710144,191.292206 919.178467,194.243729 
	C895.426453,198.484344 873.958740,207.878662 857.206726,226.227264 
	C842.609680,242.215469 834.966064,260.995300 834.098938,282.473938 
	C833.806885,289.709137 833.837585,297.151855 835.209290,304.210480 
	C836.925903,313.044556 839.087402,322.111145 842.995178,330.133179 
	C850.803711,346.162689 864.380920,356.857971 879.951233,364.951813 
	C902.063110,376.446167 926.182190,381.931549 950.097290,388.058502 
	C966.278076,392.203949 982.450195,396.188049 996.735596,405.807678 
	C1012.985291,416.750031 1010.346252,442.918610 997.066772,453.114868 
	C985.515930,461.983795 972.211731,464.192841 958.496094,464.824951 
	C951.352051,465.154205 944.105896,463.828857 936.938477,462.976318 
	C919.329285,460.881775 903.263123,454.339722 888.496765,444.827148 
	C879.470764,439.012512 870.968445,432.353668 862.470886,425.764587 
	C858.735291,422.868011 856.492310,423.973206 853.932251,427.138947 
	C849.593567,432.503967 844.977966,437.643951 840.566956,442.951874 
	C834.601440,450.130524 828.904602,457.542755 822.715698,464.520660 
	C820.017334,467.563049 821.184326,469.718658 823.298401,471.683563 
	C826.527954,474.685150 830.139526,477.273132 833.557983,480.074463 
	C848.176208,492.053802 864.430115,501.160675 881.995483,508.076294 
	C899.843079,515.103027 918.165466,519.561340 937.369934,521.191467 
	C955.595947,522.738525 973.624329,522.392944 991.454773,518.677612 
	C1007.489563,515.336548 1022.475098,509.101990 1036.447388,499.034668 
M1390.935669,479.638702 
	C1397.152588,485.456299 1402.972046,491.783691 1409.660522,496.996338 
	C1430.032349,512.873047 1453.578857,521.013794 1479.128052,522.835388 
	C1491.910278,523.746704 1504.708130,522.910583 1517.471191,520.166382 
	C1557.310669,511.600555 1587.132324,489.808929 1605.921753,453.730225 
	C1618.738892,429.119263 1622.377197,402.830353 1618.130371,375.230164 
	C1614.042725,348.664276 1602.504761,325.961639 1584.106323,306.837189 
	C1573.552856,295.866974 1560.896118,287.538940 1547.012329,280.866943 
	C1521.459839,268.587463 1494.470337,266.160889 1467.233398,270.358917 
	C1437.817871,274.892731 1412.604126,288.542480 1392.286377,311.034821 
	C1373.038330,332.342987 1362.846680,357.354218 1360.173828,385.482056 
	C1358.912476,398.756226 1359.854004,412.163391 1362.991577,425.287628 
	C1367.812256,445.452087 1376.454346,463.633728 1390.935669,479.638702 
M1653.000000,410.500000 
	C1653.768433,430.841278 1650.972778,451.327881 1655.056274,471.522491 
	C1658.045410,486.305603 1664.351807,499.172791 1676.913452,508.565002 
	C1689.986572,518.339661 1705.098999,521.120117 1720.658081,521.808838 
	C1729.464355,522.198669 1738.602051,521.712219 1747.143311,519.711365 
	C1756.655518,517.483093 1765.638306,513.027039 1774.897949,509.668945 
	C1777.895386,508.581940 1778.049927,506.421753 1778.035034,503.778961 
	C1777.959229,490.285675 1778.015381,476.791656 1777.993408,463.297974 
	C1777.982666,456.776337 1775.575684,455.707458 1769.241089,457.804901 
	C1760.657837,460.646942 1751.888672,463.181335 1743.005737,464.755371 
	C1738.742310,465.510773 1733.827271,464.397064 1729.570068,463.013824 
	C1719.901367,459.872375 1715.989502,452.424011 1715.993774,442.572357 
	C1716.009155,406.589111 1716.000000,370.605896 1716.000000,334.622650 
	C1716.000000,332.853729 1716.000000,331.084808 1716.000000,329.000000 
	C1718.396851,329.000000 1720.201050,329.000000 1722.005249,329.000000 
	C1738.830688,328.999939 1755.656372,329.027740 1772.481567,328.976898 
	C1775.942383,328.966461 1779.077515,328.537048 1779.040649,323.722137 
	C1778.927368,308.896576 1778.966797,294.069458 1779.020142,279.243225 
	C1779.035645,274.936462 1777.073364,272.933655 1772.762939,272.981506 
	C1766.266846,273.053680 1759.769165,273.000031 1753.272217,273.000000 
	C1740.993164,272.999939 1728.714233,273.000000 1716.000000,273.000000 
	C1716.000000,270.915649 1716.000000,269.287201 1716.000000,267.658783 
	C1716.000000,250.333511 1715.994507,233.008255 1716.003540,215.682983 
	C1716.005737,211.566177 1715.055908,208.843079 1709.886353,208.913300 
	C1692.897461,209.144012 1675.902832,209.072876 1658.911621,208.948441 
	C1654.708252,208.917664 1652.853882,210.406601 1652.948730,214.750565 
	C1653.126953,222.909515 1653.000122,231.075165 1653.000000,239.238022 
	C1653.000000,250.349792 1653.000000,261.461548 1653.000000,273.000427 
	C1644.731812,273.000427 1636.943604,273.000305 1629.155518,273.000488 
	C1625.062744,273.000580 1622.838623,274.419128 1622.916382,279.141449 
	C1623.149170,293.297699 1622.998291,307.460236 1623.000854,321.620300 
	C1623.002075,328.190521 1623.779175,328.977448 1630.414795,328.995392 
	C1637.846680,329.015472 1645.278442,329.000000 1653.000000,329.000000 
	C1653.000000,356.243591 1653.000000,382.871796 1653.000000,410.500000 
M1845.498291,518.000000 
	C1849.996460,518.000000 1854.494751,518.024963 1858.992798,517.993591 
	C1864.662354,517.954102 1865.990112,516.672180 1865.991089,511.027222 
	C1866.004395,434.059509 1866.003540,357.091797 1865.992798,280.124084 
	C1865.992065,274.282379 1864.662231,273.011566 1858.644409,273.005524 
	C1842.651123,272.989380 1826.657837,272.993317 1810.664551,273.003235 
	C1804.133423,273.007294 1803.002686,274.160156 1803.002441,280.814758 
	C1802.998535,357.615875 1802.998901,434.416992 1803.002441,511.218109 
	C1803.002686,517.026733 1803.952759,517.973633 1810.013428,517.990601 
	C1821.508545,518.022827 1833.003784,518.000000 1845.498291,518.000000 
M1866.949341,182.712158 
	C1856.663818,170.005264 1843.780518,163.782791 1827.233276,167.023636 
	C1817.234375,168.981918 1809.122559,173.934845 1802.914062,182.202194 
	C1792.866821,195.581299 1793.186157,217.771637 1804.586548,231.028244 
	C1813.177734,241.018051 1824.536499,245.482269 1837.360474,244.795746 
	C1847.912720,244.230835 1856.905151,239.559082 1864.272827,231.402924 
	C1875.562500,218.905304 1877.579590,197.268875 1866.949341,182.712158 
M2269.000000,311.502502 
	C2268.631592,322.804535 2272.863525,332.544495 2280.837158,340.039276 
	C2288.527832,347.268341 2298.195557,350.321411 2309.082520,350.112488 
	C2327.153809,349.765808 2344.164307,334.816803 2346.003174,315.423462 
	C2347.965332,294.734100 2333.011475,277.126038 2314.745117,273.635651 
	C2290.873047,269.074097 2268.518066,288.265717 2269.000000,311.502502 
z"
      />
      <path
        fill="#1ED760"
        opacity="1.000000"
        stroke="none"
        d="
M19.000668,382.768738 
	C18.666754,373.863739 18.017174,365.431610 18.061850,357.003143 
	C18.176140,335.440430 20.243238,314.072113 23.838631,292.766754 
	C30.274740,254.628067 43.547550,218.977478 62.077362,185.268402 
	C73.008995,165.381760 85.537567,146.430603 100.936790,129.598251 
	C110.656891,118.973557 120.057556,107.968567 130.576508,98.182137 
	C149.154877,80.897514 169.576859,65.914490 191.543259,53.103615 
	C213.968658,40.025066 237.447723,29.193756 262.396179,22.082550 
	C279.386810,17.239603 296.746979,13.579736 314.081879,10.085307 
	C337.883881,5.287214 362.124237,4.947989 386.193604,6.281829 
	C404.412903,7.291478 422.601044,10.241999 440.602325,13.453728 
	C462.549622,17.369490 483.724274,24.431982 504.198639,33.172665 
	C529.746643,44.079334 553.727295,57.871635 576.066406,74.495697 
	C591.601074,86.056038 605.866577,98.977722 619.365906,112.823029 
	C637.283142,131.199463 652.709656,151.425705 666.136353,173.305115 
	C681.033508,197.580795 693.201477,223.093246 700.944519,250.461197 
	C705.842163,267.771820 709.337769,285.502197 712.936340,303.151520 
	C717.553955,325.798950 717.861267,348.846100 716.764954,371.772400 
	C716.009644,387.568237 713.190125,403.278839 711.023438,418.990295 
	C708.346802,438.398956 702.612061,457.053162 695.946655,475.386475 
	C685.095703,505.232269 670.315796,533.012268 651.781311,558.871887 
	C639.361084,576.200867 625.685242,592.396423 610.301514,607.070618 
	C590.237183,626.209534 568.343018,643.003235 544.313538,657.006897 
	C523.002502,669.426392 500.726807,679.637390 477.298737,687.239685 
	C457.477814,693.671509 437.356964,699.019348 416.512085,701.009155 
	C405.232758,702.085815 393.946411,703.110657 382.648468,703.962769 
	C375.566284,704.496826 368.392731,705.434814 361.371735,704.850708 
	C342.928833,703.316467 324.509399,701.342651 306.150055,698.999817 
	C281.484833,695.852173 258.010742,688.070496 235.063416,678.868774 
	C208.350357,668.156982 183.319290,654.165955 160.427612,636.712341 
	C148.425095,627.561157 137.169724,617.393799 125.905914,607.317566 
	C103.254005,587.054016 85.207268,562.969788 69.093246,537.376526 
	C55.245346,515.382507 44.321545,491.867523 36.376205,467.233032 
	C30.663601,449.521149 27.035501,431.101196 23.124622,412.858398 
	C21.040918,403.138702 20.331125,393.124512 19.000668,382.768738 
M613.509766,309.992554 
	C629.584717,292.259003 623.693787,269.132751 605.534363,259.304138 
	C591.094971,251.489014 576.422668,244.025604 561.467529,237.260010 
	C537.314392,226.333298 512.132385,218.166779 486.347961,212.056564 
	C470.381897,208.273041 454.286835,204.951477 438.137146,202.048660 
	C422.726044,199.278595 407.209320,196.986954 391.663574,195.109711 
	C379.103607,193.593002 366.446228,192.502548 353.805328,192.129822 
	C336.795349,191.628296 319.740753,191.488998 302.739838,192.121841 
	C281.004181,192.930984 259.292969,194.524292 237.591812,196.068848 
	C228.949905,196.683914 220.263306,197.560974 211.772247,199.217056 
	C195.035309,202.481430 178.333435,206.024277 161.794983,210.162994 
	C154.338821,212.028900 146.258972,214.198303 140.291595,218.670929 
	C125.013031,230.122375 122.088440,249.764664 135.446823,265.125092 
	C142.108459,272.785095 155.457809,278.204071 164.886230,275.578979 
	C190.956650,268.320404 217.226562,262.176453 244.295059,259.949768 
	C257.864014,258.833588 271.385559,256.436035 284.955994,256.201111 
	C307.868256,255.804337 330.810822,256.393494 353.723846,257.107269 
	C366.197205,257.495880 378.671204,258.680939 391.081329,260.073364 
	C406.012939,261.748718 420.998871,263.384308 435.743378,266.184570 
	C461.582428,271.091949 487.089172,277.453156 511.900513,286.398010 
	C533.372375,294.138947 554.261536,303.033081 573.836487,314.913696 
	C586.807007,322.785919 601.741516,320.891846 613.509766,309.992554 
M562.984497,421.521393 
	C578.775513,408.928436 576.938782,387.893738 560.728699,377.444244 
	C552.950623,372.430206 544.805237,367.850098 536.422119,363.939667 
	C520.521179,356.522369 504.672272,348.757874 488.169434,342.927368 
	C468.473328,335.968750 448.301208,330.106415 427.984192,325.232880 
	C410.597992,321.062378 392.763367,318.635010 375.037811,316.048157 
	C362.233307,314.179443 349.293243,312.341278 336.394775,312.199036 
	C308.092285,311.886841 279.715149,310.668457 251.533997,314.963013 
	C240.871918,316.587799 230.051285,317.317169 219.483368,319.379364 
	C205.470337,322.113922 191.612183,325.664215 177.729492,329.036926 
	C168.078720,331.381561 159.015442,334.693665 153.891846,344.324402 
	C149.453857,352.666412 149.961349,365.059753 155.687500,372.111420 
	C162.899002,380.992340 172.013550,385.845245 183.607025,382.637329 
	C205.593918,376.553467 227.628799,370.846344 250.461884,368.981262 
	C267.010864,367.629517 283.559265,365.331573 300.116058,365.256714 
	C319.899719,365.167206 339.710693,366.729218 359.473389,368.100769 
	C370.723297,368.881500 382.021057,370.078827 393.086426,372.192352 
	C419.570251,377.250977 445.799652,383.468262 471.082214,393.072449 
	C491.186432,400.709564 510.932617,409.174774 529.181885,420.682068 
	C540.136169,427.589539 551.013000,429.847260 562.984497,421.521393 
M529.723938,503.739410 
	C531.364807,493.258240 528.136169,484.871643 518.806946,479.402771 
	C497.559479,466.947449 475.520416,456.134613 452.238251,447.982330 
	C425.427551,438.594452 397.987427,431.858521 369.684601,429.030365 
	C353.988556,427.461945 338.270172,425.763885 322.522491,425.173340 
	C309.124023,424.670868 295.669189,425.670593 282.239319,426.006592 
	C281.906281,426.014954 281.571350,426.013641 281.240967,426.050140 
	C269.354706,427.364197 257.456482,428.581146 245.587219,430.034302 
	C237.324905,431.045868 229.059204,432.144165 220.872162,433.629089 
	C208.391968,435.892761 196.014847,438.730133 183.525558,440.936676 
	C172.899216,442.814056 163.249924,454.488129 165.048645,463.932251 
	C167.579498,477.220398 178.543854,485.246521 190.907257,482.962311 
	C209.152878,479.591339 227.341583,475.817169 245.686264,473.106232 
	C261.565216,470.759644 277.596893,468.764557 293.616180,468.211945 
	C316.751343,467.413757 339.972900,467.280487 362.973328,471.000702 
	C377.452454,473.342651 392.129272,474.898895 406.345062,478.344086 
	C439.080719,486.277557 470.060547,498.911072 498.842377,516.524353 
	C509.861145,523.267395 525.945679,517.162415 529.723938,503.739410 
z"
      />
      <path
        fill="#1ED760"
        opacity="1.000000"
        stroke="none"
        d="
M2210.966797,411.856262 
	C2205.619629,425.686188 2200.326416,439.127228 2194.991211,452.551636 
	C2183.004150,482.712769 2171.657959,513.152771 2158.743164,542.911743 
	C2151.244629,560.190308 2139.688477,575.063477 2121.912109,583.491333 
	C2113.160400,587.640686 2103.820068,589.786743 2094.026855,590.046631 
	C2077.996338,590.471863 2062.116943,589.852478 2046.948242,583.990601 
	C2040.737183,581.590332 2034.675537,578.768555 2028.680176,575.861816 
	C2026.249634,574.683350 2025.271362,572.685791 2026.653687,569.676086 
	C2032.556519,556.824585 2038.250488,543.876953 2044.005127,530.957458 
	C2045.726318,527.093384 2048.350830,525.993164 2052.201904,528.030762 
	C2060.640381,532.495728 2069.513428,535.402649 2079.136475,536.041077 
	C2091.363525,536.852173 2098.652832,530.174622 2105.227295,520.852783 
	C2110.358643,513.577454 2106.824951,507.892914 2104.225342,501.615234 
	C2094.410645,477.914520 2084.667480,454.183990 2074.786865,430.510895 
	C2065.278809,407.730896 2055.600098,385.022247 2046.064697,362.253540 
	C2042.177124,352.970734 2038.399902,343.639893 2034.715576,334.274567 
	C2033.236694,330.515442 2031.201050,328.758301 2026.723877,328.868805 
	C2012.408813,329.222229 1998.079590,329.000000 1983.000000,329.000000 
	C1983.000000,331.067871 1983.000000,332.982544 1983.000000,334.897186 
	C1983.000000,393.053619 1983.000366,451.210052 1982.999512,509.366486 
	C1982.999390,517.207458 1982.186523,517.999207 1974.151245,517.999756 
	C1958.487305,518.000732 1942.823364,517.958679 1927.159668,518.023376 
	C1921.983521,518.044739 1919.865723,516.706421 1919.884766,510.860962 
	C1920.074707,452.371857 1920.000000,393.881927 1920.000000,335.392212 
	C1920.000000,333.431152 1920.000000,331.470093 1920.000000,329.000000 
	C1912.157349,329.000000 1904.710205,328.940643 1897.264526,329.018921 
	C1891.382324,329.080719 1889.757446,327.250366 1889.884277,321.963165 
	C1890.223877,307.807129 1890.171265,293.634399 1889.906250,279.475189 
	C1889.814575,274.580811 1891.948853,272.976837 1896.325073,272.992615 
	C1903.978027,273.020172 1911.631348,273.000305 1920.000000,273.000305 
	C1920.000000,267.895416 1919.939575,262.971130 1920.010498,258.048737 
	C1920.296509,238.189072 1924.405273,219.418961 1937.195801,203.616058 
	C1946.400635,192.243378 1958.864502,185.365494 1973.098877,183.253082 
	C1985.606323,181.396988 1998.469238,180.751373 2011.100830,181.296387 
	C2020.896851,181.719025 2030.564697,184.875916 2040.316650,186.669632 
	C2044.372437,187.415604 2044.999268,190.183029 2044.999512,193.530533 
	C2045.000366,207.361435 2045.017944,221.192368 2044.990967,235.023224 
	C2044.980713,240.301056 2042.617310,242.046036 2037.371338,240.704575 
	C2031.877930,239.299881 2026.469604,237.146606 2020.894897,236.571198 
	C2011.801514,235.632599 2002.380249,234.252640 1993.780884,238.845810 
	C1984.938110,243.569046 1983.164307,252.402512 1982.081299,261.271942 
	C1981.600830,265.206726 1982.000366,269.248993 1982.000366,274.000000 
	C1983.896729,274.000000 1985.644043,274.000000 1987.391479,274.000000 
	C2015.219849,274.000000 2043.049072,274.099762 2070.875977,273.905426 
	C2075.723633,273.871582 2077.555908,275.760040 2079.206055,280.178375 
	C2089.026611,306.473206 2099.290527,332.602112 2109.360840,358.804047 
	C2118.266357,381.976135 2127.115479,405.169922 2135.984619,428.356110 
	C2137.195068,431.520966 2138.376221,434.697235 2139.869629,438.661499 
	C2141.071289,436.016571 2142.192871,433.987427 2142.943848,431.829315 
	C2153.662354,401.028320 2164.313965,370.204010 2175.016602,339.397400 
	C2181.991943,319.320007 2189.032227,299.265289 2196.019775,279.192108 
	C2197.212402,275.766449 2199.002441,273.848969 2203.164307,273.903381 
	C2220.658203,274.132050 2238.156982,273.989868 2255.653809,274.005554 
	C2261.699951,274.010986 2263.198242,276.459869 2261.015137,282.053802 
	C2251.634766,306.090332 2242.330078,330.156342 2233.030762,354.224335 
	C2225.662842,373.293243 2218.342773,392.380676 2210.966797,411.856262 
z"
      />
      <path
        fill="#1ED760"
        opacity="1.000000"
        stroke="none"
        d="
M1328.003174,463.983704 
	C1316.462036,484.998962 1300.383667,500.925201 1279.132812,511.350372 
	C1266.453003,517.570740 1253.027710,520.852600 1238.849854,522.185608 
	C1219.917358,523.965515 1202.011963,520.870972 1185.132935,512.763367 
	C1177.596313,509.143280 1170.939941,503.584045 1164.169678,498.522675 
	C1161.064331,496.201233 1158.647949,492.958160 1155.000000,489.181549 
	C1155.000000,492.165344 1155.000000,493.750549 1155.000000,495.335754 
	C1155.000000,523.993896 1154.973389,552.652039 1155.020630,581.310059 
	C1155.031250,587.695557 1153.602417,589.227234 1147.391235,589.106812 
	C1131.068848,588.790527 1114.735107,588.889343 1098.408569,589.070007 
	C1093.736572,589.121643 1091.927612,587.567017 1091.931885,582.745850 
	C1092.021973,481.609406 1092.027466,380.472809 1091.917358,279.336395 
	C1091.911865,274.366577 1094.176025,272.986359 1098.460938,272.991821 
	C1114.789429,273.012604 1131.117920,272.971466 1147.446289,273.016388 
	C1152.845459,273.031219 1154.934082,275.111694 1154.983154,280.456207 
	C1155.050171,287.746307 1154.999878,295.037506 1154.999878,302.592316 
	C1159.041748,298.866028 1162.789429,295.378876 1166.571655,291.929626 
	C1179.833740,279.835327 1195.331299,272.502411 1213.123901,269.979187 
	C1235.949097,266.742340 1258.039795,269.424988 1278.771973,279.508362 
	C1293.487427,286.665344 1306.075439,296.792236 1316.451660,309.747437 
	C1330.815918,327.681946 1338.839722,348.045959 1342.063477,370.607758 
	C1346.697266,403.037994 1343.824951,434.278442 1328.003174,463.983704 
M1279.014282,382.407715 
	C1277.594727,366.474426 1270.933594,352.554382 1259.770386,341.694427 
	C1246.128174,328.422882 1229.433716,322.094147 1209.735596,324.856171 
	C1196.962646,326.647247 1185.957153,331.710419 1176.541748,340.087311 
	C1160.246948,354.584747 1153.793213,373.754333 1153.255737,394.832916 
	C1153.039551,403.313660 1154.855103,412.099518 1157.194702,420.334900 
	C1161.034546,433.851349 1168.659058,445.349945 1179.956543,453.936859 
	C1193.656616,464.349976 1208.986450,468.531311 1226.327271,466.119293 
	C1244.996948,463.522400 1258.686523,453.947998 1268.835327,438.534485 
	C1275.239624,428.807800 1278.503784,417.865631 1279.751709,406.554718 
	C1280.593628,398.924194 1279.385620,391.067505 1279.014282,382.407715 
z"
      />
      <path
        fill="#1ED760"
        opacity="1.000000"
        stroke="none"
        d="
M1036.192017,499.292786 
	C1022.475098,509.101990 1007.489563,515.336548 991.454773,518.677612 
	C973.624329,522.392944 955.595947,522.738525 937.369934,521.191467 
	C918.165466,519.561340 899.843079,515.103027 881.995483,508.076294 
	C864.430115,501.160675 848.176208,492.053802 833.557983,480.074463 
	C830.139526,477.273132 826.527954,474.685150 823.298401,471.683563 
	C821.184326,469.718658 820.017334,467.563049 822.715698,464.520660 
	C828.904602,457.542755 834.601440,450.130524 840.566956,442.951874 
	C844.977966,437.643951 849.593567,432.503967 853.932251,427.138947 
	C856.492310,423.973206 858.735291,422.868011 862.470886,425.764587 
	C870.968445,432.353668 879.470764,439.012512 888.496765,444.827148 
	C903.263123,454.339722 919.329285,460.881775 936.938477,462.976318 
	C944.105896,463.828857 951.352051,465.154205 958.496094,464.824951 
	C972.211731,464.192841 985.515930,461.983795 997.066772,453.114868 
	C1010.346252,442.918610 1012.985291,416.750031 996.735596,405.807678 
	C982.450195,396.188049 966.278076,392.203949 950.097290,388.058502 
	C926.182190,381.931549 902.063110,376.446167 879.951233,364.951813 
	C864.380920,356.857971 850.803711,346.162689 842.995178,330.133179 
	C839.087402,322.111145 836.925903,313.044556 835.209290,304.210480 
	C833.837585,297.151855 833.806885,289.709137 834.098938,282.473938 
	C834.966064,260.995300 842.609680,242.215469 857.206726,226.227264 
	C873.958740,207.878662 895.426453,198.484344 919.178467,194.243729 
	C935.710144,191.292206 952.482666,191.846558 969.315796,193.763489 
	C991.501770,196.289993 1012.372253,202.525848 1031.853027,213.117859 
	C1041.396729,218.306931 1050.275146,224.752518 1059.277832,230.888794 
	C1063.301147,233.631012 1063.504761,236.741501 1060.592896,240.852982 
	C1051.647949,253.483246 1042.674316,266.093262 1033.693481,278.698181 
	C1030.774170,282.795654 1028.599487,282.913239 1024.311401,279.856659 
	C1008.199951,268.372223 991.195923,258.442413 971.880432,253.520599 
	C955.701416,249.398026 939.192383,247.542557 922.894470,253.044861 
	C912.997070,256.386292 903.549011,261.205658 900.078796,271.975342 
	C893.961243,290.960663 899.797729,302.699066 915.594543,310.804626 
	C929.982056,318.187042 945.560059,322.251892 961.210144,326.030090 
	C983.829529,331.490753 1006.176392,337.755432 1026.812622,348.948212 
	C1041.908813,357.136230 1054.806641,367.723969 1062.919922,383.111755 
	C1067.885620,392.529663 1071.150146,402.621094 1071.012085,413.518860 
	C1070.942505,419.016754 1070.950928,424.516815 1071.008301,430.014984 
	C1071.280640,456.104645 1060.323120,477.117096 1041.779419,494.688171 
	C1040.119629,496.261047 1038.230225,497.591644 1036.192017,499.292786 
z"
      />
      <path
        fill="#1ED760"
        opacity="1.000000"
        stroke="none"
        d="
M1390.733276,479.341400 
	C1376.454346,463.633728 1367.812256,445.452087 1362.991577,425.287628 
	C1359.854004,412.163391 1358.912476,398.756226 1360.173828,385.482056 
	C1362.846680,357.354218 1373.038330,332.342987 1392.286377,311.034821 
	C1412.604126,288.542480 1437.817871,274.892731 1467.233398,270.358917 
	C1494.470337,266.160889 1521.459839,268.587463 1547.012329,280.866943 
	C1560.896118,287.538940 1573.552856,295.866974 1584.106323,306.837189 
	C1602.504761,325.961639 1614.042725,348.664276 1618.130371,375.230164 
	C1622.377197,402.830353 1618.738892,429.119263 1605.921753,453.730225 
	C1587.132324,489.808929 1557.310669,511.600555 1517.471191,520.166382 
	C1504.708130,522.910583 1491.910278,523.746704 1479.128052,522.835388 
	C1453.578857,521.013794 1430.032349,512.873047 1409.660522,496.996338 
	C1402.972046,491.783691 1397.152588,485.456299 1390.733276,479.341400 
M1437.996704,441.501373 
	C1439.205933,443.043701 1440.300903,444.694305 1441.642212,446.111511 
	C1455.215942,460.453796 1471.816284,467.392090 1491.644043,467.056854 
	C1511.232422,466.725677 1527.480591,459.559692 1540.014160,444.457031 
	C1554.057495,427.535034 1558.930786,408.082092 1556.075806,386.355194 
	C1553.712891,368.374054 1546.557983,353.172180 1532.707031,341.125824 
	C1515.592285,326.241180 1495.712036,321.584625 1474.167847,326.606171 
	C1446.935059,332.953583 1430.772095,350.906525 1424.907227,378.222443 
	C1420.019775,400.985687 1423.603271,422.006866 1437.996704,441.501373 
z"
      />
      <path
        fill="#1ED760"
        opacity="1.000000"
        stroke="none"
        d="
M1653.000000,410.000000 
	C1653.000000,382.871796 1653.000000,356.243591 1653.000000,329.000000 
	C1645.278442,329.000000 1637.846680,329.015472 1630.414795,328.995392 
	C1623.779175,328.977448 1623.002075,328.190521 1623.000854,321.620300 
	C1622.998291,307.460236 1623.149170,293.297699 1622.916382,279.141449 
	C1622.838623,274.419128 1625.062744,273.000580 1629.155518,273.000488 
	C1636.943604,273.000305 1644.731812,273.000427 1653.000000,273.000427 
	C1653.000000,261.461548 1653.000000,250.349792 1653.000000,239.238022 
	C1653.000122,231.075165 1653.126953,222.909515 1652.948730,214.750565 
	C1652.853882,210.406601 1654.708252,208.917664 1658.911621,208.948441 
	C1675.902832,209.072876 1692.897461,209.144012 1709.886353,208.913300 
	C1715.055908,208.843079 1716.005737,211.566177 1716.003540,215.682983 
	C1715.994507,233.008255 1716.000000,250.333511 1716.000000,267.658783 
	C1716.000000,269.287201 1716.000000,270.915649 1716.000000,273.000000 
	C1728.714233,273.000000 1740.993164,272.999939 1753.272217,273.000000 
	C1759.769165,273.000031 1766.266846,273.053680 1772.762939,272.981506 
	C1777.073364,272.933655 1779.035645,274.936462 1779.020142,279.243225 
	C1778.966797,294.069458 1778.927368,308.896576 1779.040649,323.722137 
	C1779.077515,328.537048 1775.942383,328.966461 1772.481567,328.976898 
	C1755.656372,329.027740 1738.830688,328.999939 1722.005249,329.000000 
	C1720.201050,329.000000 1718.396851,329.000000 1716.000000,329.000000 
	C1716.000000,331.084808 1716.000000,332.853729 1716.000000,334.622650 
	C1716.000000,370.605896 1716.009155,406.589111 1715.993774,442.572357 
	C1715.989502,452.424011 1719.901367,459.872375 1729.570068,463.013824 
	C1733.827271,464.397064 1738.742310,465.510773 1743.005737,464.755371 
	C1751.888672,463.181335 1760.657837,460.646942 1769.241089,457.804901 
	C1775.575684,455.707458 1777.982666,456.776337 1777.993408,463.297974 
	C1778.015381,476.791656 1777.959229,490.285675 1778.035034,503.778961 
	C1778.049927,506.421753 1777.895386,508.581940 1774.897949,509.668945 
	C1765.638306,513.027039 1756.655518,517.483093 1747.143311,519.711365 
	C1738.602051,521.712219 1729.464355,522.198669 1720.658081,521.808838 
	C1705.098999,521.120117 1689.986572,518.339661 1676.913452,508.565002 
	C1664.351807,499.172791 1658.045410,486.305603 1655.056274,471.522491 
	C1650.972778,451.327881 1653.768433,430.841278 1653.000000,410.000000 
z"
      />
      <path
        fill="#1ED760"
        opacity="1.000000"
        stroke="none"
        d="
M1844.998657,518.000000 
	C1833.003784,518.000000 1821.508545,518.022827 1810.013428,517.990601 
	C1803.952759,517.973633 1803.002686,517.026733 1803.002441,511.218109 
	C1802.998901,434.416992 1802.998535,357.615875 1803.002441,280.814758 
	C1803.002686,274.160156 1804.133423,273.007294 1810.664551,273.003235 
	C1826.657837,272.993317 1842.651123,272.989380 1858.644409,273.005524 
	C1864.662231,273.011566 1865.992065,274.282379 1865.992798,280.124084 
	C1866.003540,357.091797 1866.004395,434.059509 1865.991089,511.027222 
	C1865.990112,516.672180 1864.662354,517.954102 1858.992798,517.993591 
	C1854.494751,518.024963 1849.996460,518.000000 1844.998657,518.000000 
z"
      />
      <path
        fill="#1ED760"
        opacity="1.000000"
        stroke="none"
        d="
M1867.014893,183.073868 
	C1877.579590,197.268875 1875.562500,218.905304 1864.272827,231.402924 
	C1856.905151,239.559082 1847.912720,244.230835 1837.360474,244.795746 
	C1824.536499,245.482269 1813.177734,241.018051 1804.586548,231.028244 
	C1793.186157,217.771637 1792.866821,195.581299 1802.914062,182.202194 
	C1809.122559,173.934845 1817.234375,168.981918 1827.233276,167.023636 
	C1843.780518,163.782791 1856.663818,170.005264 1867.014893,183.073868 
z"
      />
      <path
        fill="#1ED760"
        opacity="1.000000"
        stroke="none"
        d="
M2269.000000,311.031921 
	C2268.518066,288.265717 2290.873047,269.074097 2314.745117,273.635651 
	C2333.011475,277.126038 2347.965332,294.734100 2346.003174,315.423462 
	C2344.164307,334.816803 2327.153809,349.765808 2309.082520,350.112488 
	C2298.195557,350.321411 2288.527832,347.268341 2280.837158,340.039276 
	C2272.863525,332.544495 2268.631592,322.804535 2269.000000,311.031921 
M2328.931641,337.556335 
	C2338.384277,328.233856 2343.359131,317.350494 2339.958496,303.913147 
	C2336.385010,289.794922 2327.392578,280.642059 2313.005859,278.223022 
	C2300.719238,276.157135 2289.611084,280.135468 2281.800781,289.947693 
	C2272.881836,301.152985 2270.913086,313.946533 2277.630127,327.069702 
	C2286.896973,345.174469 2310.654785,351.510590 2328.931641,337.556335 
z"
      />
      <path
        fill="transparent"
        opacity="1.000000"
        stroke="none"
        d="
M613.251282,310.251160 
	C601.741516,320.891846 586.807007,322.785919 573.836487,314.913696 
	C554.261536,303.033081 533.372375,294.138947 511.900513,286.398010 
	C487.089172,277.453156 461.582428,271.091949 435.743378,266.184570 
	C420.998871,263.384308 406.012939,261.748718 391.081329,260.073364 
	C378.671204,258.680939 366.197205,257.495880 353.723846,257.107269 
	C330.810822,256.393494 307.868256,255.804337 284.955994,256.201111 
	C271.385559,256.436035 257.864014,258.833588 244.295059,259.949768 
	C217.226562,262.176453 190.956650,268.320404 164.886230,275.578979 
	C155.457809,278.204071 142.108459,272.785095 135.446823,265.125092 
	C122.088440,249.764664 125.013031,230.122375 140.291595,218.670929 
	C146.258972,214.198303 154.338821,212.028900 161.794983,210.162994 
	C178.333435,206.024277 195.035309,202.481430 211.772247,199.217056 
	C220.263306,197.560974 228.949905,196.683914 237.591812,196.068848 
	C259.292969,194.524292 281.004181,192.930984 302.739838,192.121841 
	C319.740753,191.488998 336.795349,191.628296 353.805328,192.129822 
	C366.446228,192.502548 379.103607,193.593002 391.663574,195.109711 
	C407.209320,196.986954 422.726044,199.278595 438.137146,202.048660 
	C454.286835,204.951477 470.381897,208.273041 486.347961,212.056564 
	C512.132385,218.166779 537.314392,226.333298 561.467529,237.260010 
	C576.422668,244.025604 591.094971,251.489014 605.534363,259.304138 
	C623.693787,269.132751 629.584717,292.259003 613.251282,310.251160 
z"
      />
      <path
        fill="transparent"
        opacity="1.000000"
        stroke="none"
        d="
M562.711731,421.781158 
	C551.013000,429.847260 540.136169,427.589539 529.181885,420.682068 
	C510.932617,409.174774 491.186432,400.709564 471.082214,393.072449 
	C445.799652,383.468262 419.570251,377.250977 393.086426,372.192352 
	C382.021057,370.078827 370.723297,368.881500 359.473389,368.100769 
	C339.710693,366.729218 319.899719,365.167206 300.116058,365.256714 
	C283.559265,365.331573 267.010864,367.629517 250.461884,368.981262 
	C227.628799,370.846344 205.593918,376.553467 183.607025,382.637329 
	C172.013550,385.845245 162.899002,380.992340 155.687500,372.111420 
	C149.961349,365.059753 149.453857,352.666412 153.891846,344.324402 
	C159.015442,334.693665 168.078720,331.381561 177.729492,329.036926 
	C191.612183,325.664215 205.470337,322.113922 219.483368,319.379364 
	C230.051285,317.317169 240.871918,316.587799 251.533997,314.963013 
	C279.715149,310.668457 308.092285,311.886841 336.394775,312.199036 
	C349.293243,312.341278 362.233307,314.179443 375.037811,316.048157 
	C392.763367,318.635010 410.597992,321.062378 427.984192,325.232880 
	C448.301208,330.106415 468.473328,335.968750 488.169434,342.927368 
	C504.672272,348.757874 520.521179,356.522369 536.422119,363.939667 
	C544.805237,367.850098 552.950623,372.430206 560.728699,377.444244 
	C576.938782,387.893738 578.775513,408.928436 562.711731,421.781158 
z"
      />
      <path
        fill="transparent"
        opacity="1.000000"
        stroke="none"
        d="
M529.446350,504.057861 
	C525.945679,517.162415 509.861145,523.267395 498.842377,516.524353 
	C470.060547,498.911072 439.080719,486.277557 406.345062,478.344086 
	C392.129272,474.898895 377.452454,473.342651 362.973328,471.000702 
	C339.972900,467.280487 316.751343,467.413757 293.616180,468.211945 
	C277.596893,468.764557 261.565216,470.759644 245.686264,473.106232 
	C227.341583,475.817169 209.152878,479.591339 190.907257,482.962311 
	C178.543854,485.246521 167.579498,477.220398 165.048645,463.932251 
	C163.249924,454.488129 172.899216,442.814056 183.525558,440.936676 
	C196.014847,438.730133 208.391968,435.892761 220.872162,433.629089 
	C229.059204,432.144165 237.324905,431.045868 245.587219,430.034302 
	C257.456482,428.581146 269.354706,427.364197 281.240967,426.050140 
	C281.571350,426.013641 281.906281,426.014954 282.239319,426.006592 
	C295.669189,425.670593 309.124023,424.670868 322.522491,425.173340 
	C338.270172,425.763885 353.988556,427.461945 369.684601,429.030365 
	C397.987427,431.858521 425.427551,438.594452 452.238251,447.982330 
	C475.520416,456.134613 497.559479,466.947449 518.806946,479.402771 
	C528.136169,484.871643 531.364807,493.258240 529.446350,504.057861 
z"
      />
      <path
        fill="transparent"
        opacity="1.000000"
        stroke="none"
        d="
M1279.054443,382.859863 
	C1279.385620,391.067505 1280.593628,398.924194 1279.751709,406.554718 
	C1278.503784,417.865631 1275.239624,428.807800 1268.835327,438.534485 
	C1258.686523,453.947998 1244.996948,463.522400 1226.327271,466.119293 
	C1208.986450,468.531311 1193.656616,464.349976 1179.956543,453.936859 
	C1168.659058,445.349945 1161.034546,433.851349 1157.194702,420.334900 
	C1154.855103,412.099518 1153.039551,403.313660 1153.255737,394.832916 
	C1153.793213,373.754333 1160.246948,354.584747 1176.541748,340.087311 
	C1185.957153,331.710419 1196.962646,326.647247 1209.735596,324.856171 
	C1229.433716,322.094147 1246.128174,328.422882 1259.770386,341.694427 
	C1270.933594,352.554382 1277.594727,366.474426 1279.054443,382.859863 
z"
      />
      <path
        fill="transparent"
        opacity="1.000000"
        stroke="none"
        d="
M1437.739990,441.244446 
	C1423.603271,422.006866 1420.019775,400.985687 1424.907227,378.222443 
	C1430.772095,350.906525 1446.935059,332.953583 1474.167847,326.606171 
	C1495.712036,321.584625 1515.592285,326.241180 1532.707031,341.125824 
	C1546.557983,353.172180 1553.712891,368.374054 1556.075806,386.355194 
	C1558.930786,408.082092 1554.057495,427.535034 1540.014160,444.457031 
	C1527.480591,459.559692 1511.232422,466.725677 1491.644043,467.056854 
	C1471.816284,467.392090 1455.215942,460.453796 1441.642212,446.111511 
	C1440.300903,444.694305 1439.205933,443.043701 1437.739990,441.244446 
z"
      />
      <path
        fill="transparent"
        opacity="1.000000"
        stroke="none"
        d="
M2328.623047,337.750336 
	C2310.654785,351.510590 2286.896973,345.174469 2277.630127,327.069702 
	C2270.913086,313.946533 2272.881836,301.152985 2281.800781,289.947693 
	C2289.611084,280.135468 2300.719238,276.157135 2313.005859,278.223022 
	C2327.392578,280.642059 2336.385010,289.794922 2339.958496,303.913147 
	C2343.359131,317.350494 2338.384277,328.233856 2328.623047,337.750336 
M2309.302002,290.000000 
	C2303.680908,290.000000 2298.060059,290.000000 2292.273438,290.000000 
	C2292.273438,303.865570 2292.273438,317.261169 2292.273438,330.679504 
	C2294.947021,330.679504 2297.338623,330.679504 2300.206787,330.679504 
	C2300.206787,325.955139 2300.206787,321.547333 2300.206787,317.180969 
	C2307.407715,316.825531 2307.407227,316.825806 2311.195557,322.536896 
	C2311.916748,323.624115 2312.644775,324.706635 2313.369141,325.791718 
	C2317.154541,331.460602 2319.836426,332.323578 2327.083008,330.052246 
	C2324.459473,326.265320 2322.041748,322.408417 2319.234131,318.859772 
	C2317.556641,316.739655 2317.832520,315.967773 2319.984863,314.333710 
	C2326.005859,309.762939 2328.525146,301.868103 2322.938232,295.005676 
	C2319.767334,291.111053 2315.164307,289.926147 2309.302002,290.000000 
z"
      />
      <path
        fill="#1ED760"
        opacity="1.000000"
        stroke="none"
        d="
M2309.771973,290.000000 
	C2315.164307,289.926147 2319.767334,291.111053 2322.938232,295.005676 
	C2328.525146,301.868103 2326.005859,309.762939 2319.984863,314.333710 
	C2317.832520,315.967773 2317.556641,316.739655 2319.234131,318.859772 
	C2322.041748,322.408417 2324.459473,326.265320 2327.083008,330.052246 
	C2319.836426,332.323578 2317.154541,331.460602 2313.369141,325.791718 
	C2312.644775,324.706635 2311.916748,323.624115 2311.195557,322.536896 
	C2307.407227,316.825806 2307.407715,316.825531 2300.206787,317.180969 
	C2300.206787,321.547333 2300.206787,325.955139 2300.206787,330.679504 
	C2297.338623,330.679504 2294.947021,330.679504 2292.273438,330.679504 
	C2292.273438,317.261169 2292.273438,303.865570 2292.273438,290.000000 
	C2298.060059,290.000000 2303.680908,290.000000 2309.771973,290.000000 
M2310.129639,309.000000 
	C2313.794189,309.382629 2316.423096,307.753540 2316.873291,304.203308 
	C2317.298340,300.848694 2314.954590,298.511688 2311.772217,298.136505 
	C2307.940674,297.684692 2304.015137,298.029602 2300.015869,298.029602 
	C2300.015869,301.566040 2300.001953,304.372437 2300.029785,307.178406 
	C2300.034424,307.638092 2300.222900,308.095917 2300.427002,309.000000 
	C2303.356689,309.000000 2306.289795,309.000000 2310.129639,309.000000 
z"
      />
      <path
        fill="transparent"
        opacity="1.000000"
        stroke="none"
        d="
M2309.676270,309.000000 
	C2306.289795,309.000000 2303.356689,309.000000 2300.427002,309.000000 
	C2300.222900,308.095917 2300.034424,307.638092 2300.029785,307.178406 
	C2300.001953,304.372437 2300.015869,301.566040 2300.015869,298.029602 
	C2304.015137,298.029602 2307.940674,297.684692 2311.772217,298.136505 
	C2314.954590,298.511688 2317.298340,300.848694 2316.873291,304.203308 
	C2316.423096,307.753540 2313.794189,309.382629 2309.676270,309.000000 
z"
      />
    </svg>
  );
}
