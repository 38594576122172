interface Props {
  className?: string;
  strokeWidth?: number;
}

export default function ChevronUp({ strokeWidth = 1.5, className = "icon" }: Props) {
  return (
    <svg
      className={className}
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.5 15.75l7.5-7.5 7.5 7.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
