interface Props {
  className?: string;
  strokeWidth?: number;
}

export default function BusinessJukebox({ className = "icon", strokeWidth = 1.5 }: Props) {
  return (
    <svg
      className={className}
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      viewBox="0 0 103 132"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1445_576)">
        <path
          d="M82.1402 112.21H93.0002V49.72C92.9702 27.75 74.4202 10.01 51.5302 10.01C46.1902 10.01 41.1002 10.98 36.4102 12.72"
          stroke="#FCF4EF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          d="M19.8598 24.1C13.7398 31 10.0898 39.94 10.0898 49.72V112.21H21.6898"
          stroke="#FCF4EF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          d="M74.1891 51.52L29.0391 51.39V51.35C29.0791 39.06 39.1991 29.12 51.6591 29.17C64.1191 29.17 74.1891 39.2 74.1491 51.48"
          stroke="#FCF4EF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          d="M19.2898 112.21L19.2598 118.2C19.2598 120.29 21.3998 122.01 24.1198 122.01C26.8398 122.01 29.0098 120.33 29.0098 118.2L29.0398 112.21"
          stroke="#FCF4EF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          d="M74.2195 112.21L74.1895 118.2C74.1895 120.29 76.3295 122.01 79.0495 122.01C81.7695 122.01 83.9395 120.33 83.9395 118.2L83.9695 112.21"
          stroke="#FCF4EF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          d="M85.0204 112.21V54.86C85.0204 34.7 70.0704 18.41 51.5704 18.41C33.0704 18.41 18.1504 34.7 18.1504 54.86V112.21"
          stroke="#FCF4EF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          d="M21.3203 112.21H81.7603"
          stroke="#FCF4EF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          d="M51.0898 76C54.9558 76 58.0898 72.866 58.0898 69C58.0898 65.134 54.9558 62 51.0898 62C47.2239 62 44.0898 65.134 44.0898 69C44.0898 72.866 47.2239 76 51.0898 76Z"
          fill="#283554"
          stroke="#FCF4EF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          d="M31.0898 99C31.0898 90.1634 39.1487 83 49.0898 83"
          stroke="#FCF4EF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          d="M68.0898 89H54.0898V99H68.0898V89Z"
          fill="#283554"
          stroke="#FCF4EF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
        <path
          d="M64.0898 89V86C64.0898 84.3431 62.7467 83 61.0898 83C59.4329 83 58.0898 84.3431 58.0898 86V89"
          stroke="#FCF4EF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        />
      </g>
      <defs>
        <clipPath id="clip0_1445_576">
          <rect fill="white" height="116" transform="translate(8.08984 8)" width="86.91" />
        </clipPath>
      </defs>
    </svg>
  );
}
