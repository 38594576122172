interface Props {
  className?: string;
  strokeWidth?: number;
}

export default function MiniJukebox({ className = "icon", strokeWidth = 1.5 }: Props) {
  return (
    <svg
      className={className}
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      style={{
        fill: "none",
      }}
      viewBox="0 0 31 36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1201_3741)">
        <path
          d="M21.3309 13.68H9.91376C9.91376 11.0057 12.4852 8.84571 15.6738 8.84571C18.7595 8.74285 21.3309 10.9028 21.3309 13.68Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M29.9709 34.9714V14.1943C29.9709 6.89142 23.4909 1.02856 15.5709 1.02856C7.6509 1.02856 1.1709 6.89142 1.1709 14.1943V34.9714H29.9709Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M9.50233 18V23.5543C9.50233 27.1543 12.2795 29.52 15.5709 29.52C18.8623 29.52 21.6395 27.1543 21.6395 23.5543V18H9.50233Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M26.8852 34.9714V15.3257C26.8852 9.15428 21.7423 4.11428 15.5709 4.11428C9.29661 4.11428 4.25661 9.15428 4.25661 15.3257V34.9714H1.1709V14.1943C1.1709 6.89142 7.6509 1.02856 15.5709 1.02856C23.4909 1.02856 29.9709 6.89142 29.9709 14.1943V34.9714H26.8852Z"
          fill="currentColor"
          opacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1201_3741">
          <rect fill="white" height="36" transform="translate(0.142578)" width="30.8571" />
        </clipPath>
      </defs>
    </svg>
  );
}
